$font-gilroy: 'Gilroy', Arial, Helvetica, sans-serif;
$font-brandon: 'brandon-grotesque', sans-serif;

$sizes: ("xs": 11px, "s": 22px, "m": 44px, "l": 88px, "xl": 176px);

$color-orange: #ffaa00;
$color-blue-grey: #48646d;
$color-light-grey: #f1f2f3;

$width-large: 1920px;
$width-medium: 1280px;
$width-small: 860px;