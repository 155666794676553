@import 'variables';
@import '~destyle.css/destyle';
@import 'form';
@import 'checkmark';
@import url("https://use.typekit.net/gwo1gpw.css");

@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Light.woff2') format('woff2'),
    url('/fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('/fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('/fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin padding {
    padding: map-get($sizes, "l") map-get($sizes, "xl");
    @media only screen and (max-width: $width-medium) {
        padding: map-get($sizes, "m") map-get($sizes, "l");
    }
    @media only screen and (max-width: $width-small) {
        padding: map-get($sizes, "s") map-get($sizes, "m");
    }
}

@mixin padding-small {
    padding: map-get($sizes, "s") map-get($sizes, "s");
    @media only screen and (max-width: $width-medium) {
        padding: map-get($sizes, "s") map-get($sizes, "s");
    }
    @media only screen and (max-width: $width-small) {
        padding: map-get($sizes, "xs") map-get($sizes, "xs");
    }
}

body.has-overlay {
    overflow: hidden;
    main {
        // filter: blur(2px);
    }
}

html{
    scroll-behavior: smooth;
}

:root {
    font-size: 62.5%;
}

body {
    overflow-x: hidden;
    color: $color-blue-grey;
    font-family: $font-gilroy;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.6rem;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
}

ul:not([class]) {
    list-style-type: disc;
    margin: 0 0 0 14px;
}

ul.list {

    li{
        position: relative;
        margin-bottom: 20px;
        padding-left: 40px;
        min-height: 60px;

        &:last-child{
            margin-bottom: 0;
        }

        &:before{
            position: absolute;
            width: 30px;
            height: 30px;
            left: 0;
            content: "";
            background: url('/images/assets/arrow-orange.svg') no-repeat left 5px;
            padding-left: 10px;
            transform: rotate(-90deg);
        }

        @media only screen and (max-width: $width-medium) {
            padding: 5px;
            width: 100%;
            padding-left: 40px;

            &:before{
                left: 0;
            }
        }
    }
}

p {
    margin-bottom: map-get($sizes, "xs");
    &:last-child {
        margin-bottom: 0;
    }
}

strong {
    font-weight: 500;
    color: $color-orange;
}

h1.title-xxl{
    color: rgb(255,184,41);
    position: absolute;
    left: 20%;
    transform: scale(1.6);
    filter: blur(2px);

    @media only screen and (max-width: $width-medium) {
        transform: scale(1.4);
        left: 10%;
    }
}

h1 {
    position: relative;
    font-size: 16rem;
    line-height: 16rem;

    @media only screen and (max-width: $width-medium) {
        font-size: 11rem;
        line-height: 11rem;
        top: map-get($sizes, 'm')
    }

    @media only screen and (max-width: $width-small) {
        position: absolute;
        top: 0;
        margin-top: 30px;
        line-height: 4.5rem;
        font-size: 5rem;
    }
}

h2 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    margin-bottom: map-get($sizes, "m");

    @media only screen and (max-width: $width-medium) {
        font-size: 3.5rem;
        line-height: 3.5rem;
        margin-bottom: map-get($sizes, "s");
    }

    @media only screen and (max-width: $width-small) {
        font-size: 3.0rem;
        line-height: 3.0rem;
        margin-bottom: map-get($sizes, "s");
    }
}

h3 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-bottom: map-get($sizes, "s");

    @media only screen and (max-width: $width-medium) {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: map-get($sizes, "xs");
    }

    @media only screen and (max-width: $width-small) {
        font-size: 2.25rem;
        line-height: 3.0rem;
        margin-bottom: map-get($sizes, "xs");
    }
}

h4 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: map-get($sizes, "s");

    @media only screen and (max-width: $width-medium) {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: map-get($sizes, "xs");
    }
}

.columns {
    column-count: 2;
    column-gap: map-get($sizes, "l");
    @media only screen and (max-width: $width-medium) {
        column-gap: map-get($sizes, "m");
    }
    @media only screen and (max-width: $width-small) {
        column-count: 1;
        column-gap: 0;
    }

}

.card {
    background-color: $color-light-grey;
    position: relative;
    min-height: 220px;

    &:hover {
        // opacity: 0.6;
        cursor: pointer;
        .card-button .arrow-right{
            right: 0;
        }

        .card-button .arrow-down{
            bottom: -8px;
        }
    }

    @include padding-small;

    padding-bottom: map-get($sizes, "m");
    @media only screen and (max-width: $width-medium) {
        padding-bottom: map-get($sizes, "m");
    }
    @media only screen and (max-width: $width-small) {
        padding-bottom: map-get($sizes, "m");
    }

    .tags {
        margin-bottom: map-get($sizes, "s");
    }

    .description {
        margin-bottom: map-get($sizes, "s");
        max-height: 204px;
        overflow-y: auto;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 20px;
        font-family: $font-brandon;

        @media only screen and (min-width: $width-medium) {
            margin-top: -(map-get($sizes, "xs"));
        }

        li {
            display: flex;
            .divider {
                display: block;
                margin: 0 12px;
                line-height: 16px;
            }
            &:last-child {
                .divider {
                    display: none;
                }
            }
        }


    }

    .card-button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: map-get($sizes, "m");
        background-color: $color-orange;
        color: white;

        span {
            position: absolute;
            left: map-get($sizes, 's');
            top: 50%;
            transform: translate(0, -50%);
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;

            @media only screen and (max-width: $width-small) {
                left: map-get($sizes, 'xs');
            }
        }

        .arrow-down{
            position: absolute;
            right: map-get($sizes, 's');
            bottom: 0;
            transition: bottom 100ms;
            height: 32px;
        }

        .arrow-right{
            position: absolute;
            bottom: 50%;
            right: map-get($sizes, 's');
            transform: translate(0, 50%);
            height: 25px;
            transition: right 100ms;
        }
    }

}

main {
    margin: 0 auto;
    width: 100%;
    max-width: $width-large;
    min-height: 100vh;

    header.no-card{

        @media only screen and (max-width: $width-medium) {
            max-height: 400px;
        }

        @media only screen and (max-width: $width-small) {
            max-height: 240px;
        }

    }

    header {
        height: 536px;
        background-color: $color-orange;
        color: white;
        position: relative;

        @include padding;

        @media only screen and (max-width: $width-medium) {
            padding: map-get($sizes, 'm') map-get($sizes, 'm');
        }

        .titles{
            position: relative;
            margin-top: map-get($sizes, 'xs');
        }

        img.x-text-header{
            position: absolute;
            color: white;
            text-transform: uppercase;
            right: map-get($sizes, 'xl');
            top: map-get($sizes, 'm');
            height: 28px;

            @media only screen and (max-width: $width-medium) {
                right: map-get($sizes, 'm');
                top: map-get($sizes, 'm');
                height: 24px;
            }
            @media only screen and (max-width: $width-small) {
                right: map-get($sizes, 'm');
                top:30px;
                height: 20px;
            }
        }

        .card {
            background: white;
            color: $color-blue-grey;
            position: absolute;
            bottom: -(map-get($sizes, "m"));

            right: map-get($sizes, "xl");
            width: calc(50% - #{map-get($sizes, "xl")} - #{map-get($sizes, "m")});

            @media only screen and (max-width: $width-medium) {
                right: map-get($sizes, "m");
                width: calc(50% - #{map-get($sizes, "m")} - #{map-get($sizes, "s")});
            }

            @media only screen and (max-width: $width-small) {
                width: auto;
                right: map-get($sizes, "m");
                left: map-get($sizes, "m");
            }
        }
    }

    nav {
        height: map-get($sizes, "m");
        display: flex;
        align-items: center;
        padding: 0 map-get($sizes, 'xl');

        >* {
            margin-right: map-get($sizes, 'xs');
            line-height: 14px;
        }

        .breadcrumb-current{
            height: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media only screen and (max-width: $width-medium) {
            padding: 0 map-get($sizes, 'm');
        }

        @media only screen and (max-width: $width-small) {
            margin-top: map-get($sizes, 'l');
        }

        a {
            color: $color-orange;
            font-weight: bold;
            border-bottom: 1px solid $color-orange;
        }
    }

    .content-wrapper {
        @include padding;
        padding: map-get($sizes, 'l') map-get($sizes, 'xl');

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        &:nth-child(even){
            background-color: $color-light-grey;

            &.page-header{
                background-color: white;
            }
        }

        video{
            border: 1px solid transparent;
        }

        img {
            object-fit: cover;
            margin-top: map-get($sizes, 'l');
        }

        a {
            color: $color-orange;
            text-decoration: underline;
        }

        &.page-header {
            padding-bottom: 0;

            >* {
                text-align: center;

                @media only screen and (min-width: $width-small) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .column-width {
                        width: calc(50% - #{map-get($sizes, "m")});
                    }
                }
            }
        }

        @media only screen and (max-width: $width-medium) {
            padding: map-get($sizes, 'l') map-get($sizes, 'm');

        }

        @media only screen and (max-width: $width-small) {
            flex-direction: column;
            padding: map-get($sizes, 'm') map-get($sizes, 'm');
        }



        >* {
            width: calc(50% - #{map-get($sizes, "m")});

            &:nth-child(n+3) {
                margin-top: map-get($sizes, "m");
            }
            @media only screen and (max-width: $width-medium) {
                width: calc(50% - #{map-get($sizes, "s")});
            }
            @media only screen and (max-width: $width-small) {
                width: 100%;
                margin-bottom: map-get($sizes, "m");
                &:nth-child(n+3) {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.single {
            display: block;

            >* {
                width: auto;
            }
        }
    }

    .contact-wrapper {
        color: white;
        @media only screen and (min-width: $width-small) {
            display: flex;
        }

        .contact-form {
            background-color: $color-orange;
            position: relative;
            width: 65%;

            @include padding;

            @media only screen and (max-width: $width-medium) {
                padding: map-get($sizes, 'm') map-get($sizes, 'm');
                padding-right: map-get($sizes, 'xl')
            }

            @media only screen and (max-width: $width-small) {
                width: 100%;
                padding-right: map-get($sizes, 'm')
            }

            .textarea {
                position: relative;
            }

            .decoration-arrow{
                display: block;
                position: absolute;
                width: 150px;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);

                @media only screen and (max-width: $width-medium) {

                }

                @media only screen and (max-width: $width-small) {
                    display: none;
                }
            }

            .form-name-email{
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                >div{
                    width: calc(50% -  #{map-get($sizes, 's')});
                    position: relative;
                }

                @media only screen and (max-width: $width-medium) {
                    flex-direction: column;

                    div{
                        width: 100%;
                    }
                }
            }

            label a {
                text-decoration: underline;
            }

        }


        .contact-details {
            background-color: $color-blue-grey;
            display: flex;
            flex-direction: column;
            width: 35%;

            @include padding;

            padding-right: map-get($sizes, 'xl');
            padding-left: map-get($sizes, 'l');


            @media only screen and (max-width: $width-medium) {
                padding: map-get($sizes, 'm') map-get($sizes, 'm');
            }

            @media only screen and (max-width: $width-small) {
                width: 100%;
            }

            h2 {
                position: static;
                color: white;
                position: relative;
                font-size: 66px;
            }

            .contact-data {
                margin-bottom: map-get($sizes, 's');
                line-height: 1em;
                padding-bottom: 25px;
                font-size: 16px;
            }

            p.title {
                font-weight: 700;
                font-size: 2rem;
                line-height: 2rem;
                margin: 2.5rem 0;
            }

            p.subtitle {
                font-size: 1.5rem;
                line-height: 1.6rem;
                text-transform: uppercase;
                font-family: brandon-grotesque,sans-serif;
                font-weight: 900;
                margin-bottom: 20px;
                font-style: normal;
                max-width: 112px;
            }
        }

    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: map-get($sizes, 'm') map-get($sizes, 'xl');

        img{
            height: 18px;
            width: auto;
        }

        @media only screen and (max-width: $width-medium) {
            padding: map-get($sizes, "s") map-get($sizes, "m");
            flex-direction: row;
        }

        @media only screen and (max-width: $width-small) {
            padding: map-get($sizes, "s") map-get($sizes, "s");
            flex-direction: column;

            img{
                align-self: center;
                margin-bottom: map-get($sizes, "xs");
            }

            p{
                text-align: center;
                line-height: 32px;
            }
        }
    }
}

.link-orange{
    color: $color-orange;
    font-weight: 600;
    border-bottom: 2px solid $color-orange;
}

.link-xl{
    display: block;
    width: max-content;
    font-size: 1.5em;
    line-height: 1em;
    font-weight: 700;
    color: $color-orange;
    border-bottom: 2px solid $color-orange;

    &.white {
        color: white;
        border-bottom: 2px solid white;
    }

    &.link-back {
        position: absolute;
        z-index: 2;
        bottom: map-get($sizes, "m");

        @media only screen and (max-width: $width-small) {
            display: none;
        }
    }
}

.link-lg{
    display: block;
    width: max-content;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 700;
    color: $color-orange;
    border-bottom: 2px solid $color-orange;

    &.white {
        color: white;
        border-bottom: 2px solid white;
    }
}

// .socials {
//     a {
//         font-size: 1.5rem;
//     }
// }

.about-text{
    text-align: center;
}

.open-interview {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    flex-direction: column;
    margin-bottom: map-get($sizes, 'm');
    // margin: map-get($sizes, 's') 0;
    cursor: pointer;

    @media only screen and (max-width: $width-small) {
        margin-bottom: map-get($sizes, 's');
        margin-top: map-get($sizes, 's');
    }

    h4{
        color: $color-orange;
        text-transform: uppercase;
        text-align: center;

        @media only screen and (max-width: $width-small) {
            font-size: 1em;
        }
    }

    img{
        position: absolute;
        bottom: 0;
        width: 50px;
        height: auto;
        transition: bottom 100ms;
    }

    &:hover {
        img {
            bottom: -(map-get($sizes, 'xs'));
        }
    }
}

#success{
    & > * {
        text-align: left;
    }
}


.socials {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;

    .button {
        margin: 0;
        font-size: 1.5rem;
    }

}


.overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-blue-grey, .3);

    .overlay-window {
        position: absolute;
        background-color: white;

        top: map-get($sizes, 'm');
        bottom: map-get($sizes, 'm');
        left: 50%;
        transform: translate(-50%, 0);

        width: 1080px;

        @media only screen and (max-width: $width-medium) {
            transform: none;
            width: auto;
            left: map-get($sizes, 'm');
            right: map-get($sizes, 'm');
        }

        @media only screen and (max-width: $width-small) {
            top: map-get($sizes, 's');
            left: map-get($sizes, 's');
            bottom: map-get($sizes, 's');
            right: map-get($sizes, 's');
        }

        .overlay-window-content {
            position: absolute;
            top: map-get($sizes, 'l');
            left: map-get($sizes, 'm');
            bottom: map-get($sizes, 'm');
            right: map-get($sizes, 'm');
            overflow-y: auto;

            @media only screen and (max-width: $width-small) {
                left: map-get($sizes, 's');
                bottom: map-get($sizes, 's');
                right: map-get($sizes, 's');
            }
        }

        .overlay-window-close {
            position: absolute;
            top: map-get($sizes, 's');
            right: map-get($sizes, 's');
            width: map-get($sizes, 'm');
            height: map-get($sizes, 'm');
            cursor: pointer;

            &::before {
                content: 'x';
                color: $color-orange;
                float: right;
                font-weight: 800;
                font-size: 4em;
            }
        }

    }
}

.button {
    display: block;
    padding: 0 0 8px 0;
    color: $color-orange;
    width: max-content;
    font-weight: 900;
    font-size: 2rem;
    line-height: 2rem;
    position: relative;

    &:before {
        border-bottom: 3px solid $color-orange;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
    }

    // + .button { margin-left: 10px; }

    &.lite {
        border-color: white;
        color: white;

        &:before { border-color: white; }
    }
}