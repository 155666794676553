#drop_zone {
    box-sizing: border-box;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: map-get($sizes, "s") 0;

    &.highlight {
        color: $color-blue-grey;
    }

    &:hover{
        cursor: pointer;
    }
}

#drop_zone .dropzone-label {
    margin-top: 24px;
}

.form{
    display: flex;
    flex-direction: column;
    position: relative;

    .applied{
        color: green;
        font-weight: bold;
    }

    button[type=submit]{
        width: max-content;
        align-self: flex-end;
        margin-top: map-get($sizes, 's');

        @media only screen and (max-width: $width-small){
            margin-top: map-get($sizes, 'xs');
        }

        &.hidden{
            display: none;
        }
    }

    div.form-intro{
        // margin-bottom: map-get($sizes, 's');
    }

    div{
        display: flex;
        flex-direction: column;
        margin-bottom: map-get($sizes, 's');

        &:last-child{
            margin-bottom: 0;
        }

        textarea{
            border-bottom: 1px solid grey;
            height: 200px;
            width: 100%;
            resize: vertical;
            min-height: 120px;
            max-height: 120px;
        }
    
        input, textarea {
            border-bottom: 2px solid white;
            margin-bottom: 4px;
            font-weight: 600;
            height: 48px;

            &::placeholder {
              color: rgba(white, .7);
            }
    
            &:last-child{
                margin-bottom: 0;
            }

            &.error {
              border-bottom: 1px solid red;
            }
        }
    }

    .err-msg{
        color: red;
        font-size: 13px;
        width: 100%;
        text-align: right;
    }

    #privacy-check {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;

          a {
            text-decoration: none;
            font-weight: bold;
          }

        }
      
        // Box.
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
        }

        &.error + label:before {
          border-bottom: 1px solid red;
        }
      
        // Box hover
        &:hover + label:before {
          border: none;
        }
        
        // // Box focus
        &:focus + label:before {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.12);
        }
      
        // Box checked
        &:checked + label:before {
          background: white;
        }
        
        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }
      
        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }
      
        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 12px;
          background: #ffaa00;
          width: 5px;
          height: 5px;
          box-shadow: 
            2px 0 0 #ffaa00,
            4px 0 0 #ffaa00,
            4px -2px 0 #ffaa00,
            4px -4px 0 #ffaa00,
            4px -6px 0 #ffaa00,
            4px -8px 0 #ffaa00;
            transform: rotate(45deg);
        }
      }
}


.upload {
    position: relative;
    width: 100%;
    height: 100px;
    color: white;
    font-size: 16px;
    display: flex;
    flex-direction: column;


    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 80%;
        height: 100%;
        opacity: 0;
    }    
}



